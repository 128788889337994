<template>
    <div class="common_content product_box" :style="'background-image'+':url('+ productBg +')'">
        <!-- <div class="year_1">{{isActive === 0 ? '2022' : '2021'}}{{isActive === 0 ? '春' : isActive === 1 ? '夏' : isActive === 2 ? '秋' : '冬'}}季</div>
        <div class="year_2">{{isActive === 0 ? '2022' : '2021'}} {{isActive === 0 ? 'SPRING' : isActive === 1 ? 'SUMMER' : isActive === 2 ? 'AUTUMN' : 'WINTER'}}</div> -->
        <div class="year_1">{{$route.query.small_title}}</div>
        <div class="year_2">{{$route.query.title}}</div>
        <div class="jump-box" @click="handleToBack">
            <div class="flex">
                <div class="flex-item" style="font-size:20px">全部系列</div>
                <div class="flex-item" style="font-size:28px">←</div>
            </div>
        </div>
        <div class="p_flex">
            <!-- <div v-for="(item, index) in products" :key="item.id" class="p_flex_item" @click="handleToProductDetail(item.id)"> -->
            <div v-for="(item, index) in products" :key="item.id" class="p_flex_item">
                <template v-if="item.small_image_url">
                    <!-- <img :src="item.small_image_url"> -->
                    <el-image
                        :src="item.small_image_url" 
                        :preview-src-list="[item.small_image_url]">
                    </el-image>
                </template>
                <template v-else>
                    <img v-if="index===0" src="../../assets/brand/product_01.png">
                    <img  v-if="index===1" src="../../assets/brand/product_02.png">
                    <img  v-if="index===2" src="../../assets/brand/product_03.png">
                    <img v-if="index===3" src="../../assets/brand/product_04.png">
                    <!-- <img src="../../assets/brand/product_04.png">
                    <img src="../../assets/brand/product_04.png">
                    <img src="../../assets/brand/product_04.png">
                    <img src="../../assets/brand/product_04.png">
                    <img src="../../assets/brand/product_04.png">
                    <img src="../../assets/brand/product_04.png"> -->
                </template>
                <!-- <div class="p_title">{{item.title}}</div> -->
            </div>
            <!-- <div class="p_flex_item" @click="handleToProductDetail">
                <img src="../../assets/brand/product_02.png">
                <div class="p_title">xxxx系列</div>
            </div>
            <div class="p_flex_item" @click="handleToProductDetail">
                <img src="../../assets/brand/product_03.png">
                <div class="p_title">xxxx系列</div>
            </div>
            <div class="p_flex_item" @click="handleToProductDetail">
                <img src="../../assets/brand/product_04.png">
                <div class="p_title">xxxx系列</div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            products:[],
            productBg: '',
            isActive: null,
        }
    },
    created() {
        this.getProducts()
        
    },
    mounted() {
        let category_id = this.$route.query.category_id;
        // let isActive = this.$route.query.isActive;
        // if (isActive) {
        //     this.isActive = Number(isActive)
        // } else{
        //      this.isActive = 0
        // }
        if (category_id) {
            let categoryId = Number(category_id);
            switch (categoryId) {
                case 104:
                    this.productBg = this.$cndHost+'pc/assets/brand/paul01.png';
                    break;
                case 105:
                    this.productBg = this.$cndHost+'pc/assets/brand/paul02.png';
                    break;
                case 106:
                    this.productBg = this.$cndHost+'pc/assets/brand/paul03.png';
                    break;
                case 107:
                    this.productBg = this.$cndHost+'pc/assets/brand/paul04.png';
                    break;
                case 108:
                    this.productBg = this.$cndHost+'pc/assets/brand/bobDog01.png';
                    break;
                case 109:
                    this.productBg = this.$cndHost+'pc/assets/brand/bobDog02.png';
                    break;
                case 110:
                    this.productBg = this.$cndHost+'pc/assets/brand/bobDog03.png';
                    break;
                case 111:
                    this.productBg = this.$cndHost+'pc/assets/brand/bobDog04.png';
                    break;
            
                default:
                    this.productBg = this.$cndHost+'pc/assets/brand/common_bg.png';
                    break;
            }
        }
    },
    methods:{
        handleToBack() {
            // this.$router.go(-1);
            let name;
            if (this.$route.query.flag === '1') {
                name = 'bOBDOG';
            } else{
                name = 'paulFrank';
            }
            this.$router.push({name: name, params:{noAnimate: '1'}})
        },
        handleToProductDetail(id) {
            this.$router.push({name: 'productDetail', query:{id:id,category_id: this.$route.query.category_id,}})
        },
        getProducts() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let cond= {
                params:{
                    // top_category_id: 101 || this.$route.query.top_category_id,
                    category_id: this.$route.query.category_id,
                    // is_load_extra: 1,
                    page: 1,
                    per_page: 99
                }
            };
            this.$http.get(this.$apiUrl.common.getNews,cond).then((res) => {
                this.products = res.paginate.data;
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
    },
    //创建前设置 body样式 隐藏滚动条
    beforeCreate () {
          document.querySelector('body').setAttribute('style', 'overflow:hidden;')
    },
    //销毁前清除
    beforeDestroy () {
          document.querySelector('body').removeAttribute('style')
    },
}
</script>

<style scoped>
.common_content{
    /* margin-left: 370px;
    margin-top: 4vh; */
}
.product_box{
    /* margin-right: 240px;
    margin-top: 100px;
    position: relative; */
    
        background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
}
.p_flex{
    /* display: flex; */
     width: 1108px;
    /* justify-content: space-around;
    flex-wrap: wrap; */
    float: right;
    margin-right: 6.8%;
    margin-top: 1.6%;
        height: 99vh;
    overflow-y: scroll;
}
 .p_flex::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 0;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  }
  .p_flex::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #777777;
  }
  .p_flex::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
  }
.p_flex_item{
        float: left;
    margin-left: 56px;
        margin-bottom: 28px;
}
.p_flex_item:nth-child(n+3){
        /* margin-top: 4%; */
}
.p_flex_item img{
    width: 308px;
}
.p_flex_item img:hover{
    cursor: pointer;
    box-shadow: 0px 6px 14px 0px #c9c9c9;
}
.p_flex_item .p_title{
    background: #fff;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    position: relative;
    top: -27px;
    width: 248px;
    margin-left: 20px;
}
.year_1{
font-size: 50px;
    font-family: AlibabaPuHuiTiB;
    font-weight: bolder;
    color: #FFFFFF;
    position: absolute;
    z-index: 9;
    top: 41%;
    left: 5%;
}
.year_2{
font-size: 30px;
    font-family: AlibabaPuHuiTiH;
    font-weight: bolder;
    color: #FFFFFF;
    position: absolute;
    z-index: 9;
    top: 48%;
    left: 5%;
}

@media screen and (max-width:1500px) {
    .p_flex{
    display: flex;
    width: 520px;
    justify-content: space-around;
    flex-wrap: wrap;
    float: right;
    margin-right: 7.9%;
    margin-top: 1.6%;
}
.p_flex_item{
        margin-left: 40px;

}
.p_flex_item:nth-child(n+3){
        margin-top: 4%;
}
.p_flex_item img{
    width: 208px;
}
.p_flex_item .p_title{
background: #fff;
    padding: 6px;
    font-size: 14px;
    text-align: center;
    position: relative;
    top: -21px;
    width: 150px;
    margin-left: 23px;
}
}

.jump-box{
    width: 380px;
    height: 110px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 60%;
    left: 5%;
    border-bottom: 1px solid #fff;
    padding-bottom: 18px;
}
.flex{
    display: flex;
    justify-content: space-between;
        line-height: 110px;
        margin-top: 40px;
}
.jump-box:hover{
    color: #FFA56A;
}
.p_flex_item >>> img{
    width: 308px;
    height: auto;
}
</style>